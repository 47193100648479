import { FunctionComponent, useState } from "react"
// @ts-ignore
import React from "react";
import styled from "styled-components";

const RadioButtonWrapper =  styled.div`
  display: flex;
  flex-direction: row;
  .modern-radio-container {
    margin: 24px 0;
    display: flex;
    cursor: pointer;
    user-select: none;
  }
`;

const RadioButton = styled.div`
  cursor: pointer;
  &.radio-outer-circle {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    border: 1px solid #2af5ba;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
  }
  &.unselected {
    border: 1px solid #c0c0c0;
  }
  div {
    &.radio-inner-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #2af5ba;
      transition: all 0.1s linear;
    }
    &.unselected-circle {
      width: 0;
      height: 0;
    }
  }
  :first-of-type {
    margin-bottom: 20px;
  }
`;
const RadioLabel = styled.span`
  color: #07064e;
  font-size: 16px;
  font-weight: 600;
`;
const HelperLabel = styled.span`
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
`;



const CustomRadio = ({ labelText, value, onChange, selected, helperLabel }) => {

  return (
    <RadioButtonWrapper
      className="modern-radio-container"
      onClick={() => {
        onChange(value);
      }}
    >
      <RadioButton
        className={`radio-outer-circle ${value !== selected && "unselected"}`}
      >
        <div
          className={`radio-inner-circle ${value !== selected &&
          "unselected-circle"}`}
        />
      </RadioButton>
      <RadioLabel>{labelText}<HelperLabel>{helperLabel}</HelperLabel></RadioLabel>
    </RadioButtonWrapper>
  );
};
export default CustomRadio;
