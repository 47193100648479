import { FunctionComponent, useRef, useState } from "react"
// @ts-ignore
import React from "react";
import styled from "styled-components";
import { parseToMoney } from "../../../utils/parseToMoney"
import CustomButton from "../customButton"

const MainBarWrapper = styled.div`
  margin-top: 68.1px;
  width: 1200px;
  border: 2px solid #2af5ba;
  border-radius: 30px;
  background-color: #ebf4fd;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  ::after {
    content: "";
    display: block;
    width: 2px;
    height: 60px;
    position: absolute;
    background-color: #2af5ba;
    left: 50%;
    bottom: -60px;
  }
  @media(max-width: 1250px) {
    width: 95%;
  }
  @media(max-width: 750px) {
    flex-direction: column;
    ::after {
      top: auto;
    }
  }
`;
const CalculationRow = styled.div`
  display: flex;
  text-align: center;
  font: normal normal 800 50px/45px;
  letter-spacing: 0;
  margin-bottom: 28px;
  color: #46467b;
  align-items: center;
  &.mobile {
    display: none;
  }
  @media(max-width: 750px) {
    display: none;
    &.mobile {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Column =  styled.div`
  flex-direction: column;
  margin-top: -18px;
  display: flex;
  &.math-sign {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    margin: 0 15px 0 15px;
    color: #46467b;
    @media(max-width: 600px) {
      margin: 0 10px 0 10px;
    }
  }
  &.equal {
    margin-top: 10px;
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 40px;
  }
`;
const MainText =  styled.div`
  font-size: 50px;
  font-weight: 800;
  color: #46467b;
  &.total-value {
    color: #ffb87e;
  }

  .mobile-total-value {
    margin-left: 23px;
    margin-top: 10px;
  }
  @media(max-width: 500px) {
    font-size: 30px;
  }
`;
const UpperText = styled.span`
  font-size: 12px;
  font-weight: 300;
  @media(max-width: 500px) {
    font-size: 10px;
  }
`;
const SmallText = styled.span`
  font-size: 20px;
`;
const MobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PickPlanButtonMonth = styled(CustomButton)`
  width: 50%;
  background-color: #07064E;
  color: #2af5ba;
  border-radius: 28px 0 0 0;
  &.monthly {
    background-color: #2af5ba;
    color: #07064E;
  }
`;
const PickPlanButtonYear = styled(CustomButton)`
  width: 50%;
  background-color: #07064E;
  border-radius: 0 28px  0 0;
  color: #2af5ba;
  &.yearly {
    background-color: #2af5ba;
    color: #07064E;
  }
`;

const PickPlanWrapper =  styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
`;

export interface Props {
  totalPriceProp: number
  extraServicesProp: number;
  priceMonthlyProp: number;
  setYearlyState: ()=> void;
  setMonthlyState: ()=> void;
  isMonthlyPlan: boolean;
}

const CalculatorMainBar:FunctionComponent<Props> = ({priceMonthlyProp,isMonthlyPlan, extraServicesProp, totalPriceProp, setMonthlyState, setYearlyState}) => {


  return (
  <MainBarWrapper>
    <PickPlanWrapper>
    <PickPlanButtonMonth
      text='miesięczne'
      onClick={setMonthlyState}
      className={isMonthlyPlan ? 'monthly' : ''}
    />
    <PickPlanButtonYear
      text='roczne'
      onClick={setYearlyState}
      className={!isMonthlyPlan ? 'yearly' : ''}
    />
    </PickPlanWrapper>
    <CalculationRow>
        {isMonthlyPlan ? (
          <>
            <Column>
              <UpperText>subskrypcja miesięczna</UpperText>
              <MainText>
                <span>
                ({parseToMoney(priceMonthlyProp)}
                  <SmallText>zł</SmallText>
                </span>
              </MainText>
            </Column>
            <Column className="math-sign">
              x
            </Column>
            <Column>
            <UpperText>liczba miesięcy</UpperText>
            <MainText>
            <span>
            12)
            </span>
            </MainText>
            </Column>
          </>
        ) : (
          <Column>
            <UpperText>subskrypcja roczna</UpperText>
            <MainText>
                <span>
                {parseToMoney(priceMonthlyProp * 10)}
                  <SmallText>zł</SmallText>
                </span>
            </MainText>
          </Column>
        )}



      <Column className="math-sign">
        +
      </Column>
      <Column>
        <UpperText>dodatkowe usługi</UpperText>
        <MainText>
          <span>
          {parseToMoney(extraServicesProp)}
          <SmallText>zł</SmallText>
          </span>
        </MainText>
      </Column>
      <Column className="math-sign">
        =
      </Column>
      <Column>
        <UpperText>{isMonthlyPlan ? 'suma miesięczna' : 'suma roczna'}</UpperText>
        <MainText className='total-value'>
          <span>
            {parseToMoney(totalPriceProp)}
          <SmallText>zł</SmallText>
          </span>
        </MainText>
      </Column>
    </CalculationRow>



    <CalculationRow className='mobile'>
      <MobileWrapper>
        <Column>
          <UpperText>Subskrypcja {isMonthlyPlan ? 'miesięczna' : 'roczna'}</UpperText>
          <MainText>
            <span>
              {isMonthlyPlan && ('(')}
              {parseToMoney( isMonthlyPlan ? priceMonthlyProp : priceMonthlyProp * 10)}
              <SmallText>zł</SmallText>
            </span>
          </MainText>
        </Column>
        {isMonthlyPlan && (
          <>
          <Column className="math-sign">
          x
          </Column>
          <Column>
          <UpperText>liczba miesięcy</UpperText>
          <MainText>
          <span>
          12)
          </span>
          </MainText>
          </Column>
          </>
        )}
        <Column className="math-sign">
          +
        </Column>
        <Column>
          <UpperText>dodatkowe usługi</UpperText>
          <MainText>
            <span>
            {parseToMoney(extraServicesProp)}
              <SmallText>zł</SmallText>
            </span>
          </MainText>
        </Column>
      </MobileWrapper>
      <MobileWrapper>
        <Column className="equal">
          =
        </Column>
      </MobileWrapper>
      <MobileWrapper>
        <Column>
          <MainText className='total-value'>
          <span className='mobile-total-value'>
            {parseToMoney(totalPriceProp)}
            <SmallText>zł</SmallText>
          </span>
          </MainText>
        </Column>
      </MobileWrapper>
    </CalculationRow>
  </MainBarWrapper>
  );
};
export default CalculatorMainBar;
