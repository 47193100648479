import { FunctionComponent, useState } from "react"
// @ts-ignore
import React from "react";
import styled from "styled-components";

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;
const CheckboxOutline = styled.div`
  cursor: pointer;
  height: 28px;
  width: 28px;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  &.no-border {
    border: 1px solid transparent;
  }
`;
const CheckboxFill = styled.div`
  opacity: 1;
  background-color: #2AF5BA;
  width: 29px;
  height: 29px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  position: relative;
  ::after{
    border: 2px solid #07064e;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 8px;
    opacity: 1;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
`;
const RadioLabel = styled.span`
  margin-left: 10px;
  color: #07064e;
  font-size:14px;
`;

interface CheckboxProps {
  setState: () => void;
  state: boolean;
  labelText: string;
}

const CustomCheckbox:FunctionComponent<CheckboxProps> = ({ setState, state, labelText }) => {

  return (
    <CheckboxWrapper>
      <CheckboxOutline onClick={setState} className={state ? 'no-border' : ''}>
        {state && <CheckboxFill />}
      </CheckboxOutline>
      <RadioLabel>{labelText}</RadioLabel>
    </CheckboxWrapper>
  );
};
export default CustomCheckbox;
