import * as React from "react";
import styled from "styled-components"

const HeaderWrapper = styled.div`
  text-align: center;
  width: 40%;
  margin-bottom: 70px;
  @media(max-width: 1100px) {
    width: 90%;
  }
`;

const HeaderText = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: #191c63;
  margin-bottom: 20.4px;
`;

const HeaderDescription = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: #07064e;
`;

const CustomHeaderText = ({ title, description }) => (
  <HeaderWrapper>
    <HeaderText>{title}</HeaderText>
    <HeaderDescription>{description}</HeaderDescription>
  </HeaderWrapper>
);
export default CustomHeaderText;
