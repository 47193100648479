import { FunctionComponent, useEffect, useState } from "react"
// @ts-ignore
import React from "react";
import Layout from "../components/layout"
import styled from "styled-components";
import LogoSVG from "../images/logo.svg";
import CrossPaginatorBlue from "../images/cross.svg"
import { navigate } from "gatsby"
import CustomRadio from "../components/common/customRadio"
import CustomInput from "../components/common/customInput"
import CustomCheckbox from "../components/common/customCheckbox"
import CustomButton from "../components/common/customButton"
import { stripePrices } from "../stripe-prices"
import CalculatorMainBar from "../components/common/calculator/calculator-main-bar"
import CalculatorDetails from "../components/common/calculator/calculatorDetails"
import scrollTo from "gatsby-plugin-smoothscroll"
import CustomHeaderText from "../components/common/customHeaderText"
import CalculatorImage from "../images/calculator/infografika_wydarzenia.png";

const CalculatorWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  background-color: #F4F9FF;
`;

const NavWrapper = styled.div`
  width: 100%;
  padding: 32px 34px;
  display: flex;
  justify-content: space-between;
`;
const Logo = styled.img`
  height: 36px;
  cursor: pointer;
`;
const ClosePageButton = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
const CalculatorInsertDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 700px) {
    width: 90%;
    align-self: center;
  }
`;
const InsertDataBar =  styled.div`
  display: flex;
  flex-direction: column;
`;
const InsertDataHeader = styled.p`
  color: #07064e;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
`;
const VideoPopupWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
const RadioButtonsWrapper = styled.div`
  margin: 20px 0 40px 0;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  @media(max-width: 1100px) {
    flex-direction: column-reverse;
  }
`;

const TopImage = styled.img`
  margin-left: 100px;
  align-self: flex-start;
  @media(max-width: 1100px) {
    margin-left: 0;
    width: 70%;
    margin-bottom: 70px;
    align-self: center;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  top: -8px;
  right: -190px;
  align-items: center;
  margin-left: 30px;
  span {
    margin-right: 10px;
    font-size: 14px;
    color: #07064e;
    font-weight: 700;
  }
  @media(max-width: 1100px) {
    position: relative;
    right: auto;
  }
  @media(max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
  }
  
`;
const CalculationBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export interface PlanToChooseResponse {
  id: string;
  active: boolean;
  priceMonthly: number;
  priceYearly: number;
  isMain: boolean;
  freeVotesCount: number;
  votePrice: number;
  votePriceId: string;
  generalMeetingPrice: number;
  generalMeetingPriceId: string;
  reportPrice: number;
  reportPriceId: string;
  videoConferencePrice: number;
  videoConferencePriceId: string;
  chatPrice: number;
  chatPriceId: string;
  smsPrice: number;
  smsPriceId: string;
  priceMonthlyId: string;
  priceYearlyId: string;
}


const Calculator = () => {
  const [selected, setSelected] = useState("default");
  const [isChatEnabled, setChatEnabled] = useState(false);
  const [isPdfEnabled, setPdfEnabled] = useState(false);
  const [isVideoEnabled, setVideoEnabled] = useState(false);
  const [isSmsEnabled, setSmsEnabled] = useState(false);
  const [isMonthlyPlan, setMonthlyPlan] = useState(true);

  const [usersAmount, setUsersAmount] = useState(0);
  const [questionsAmount, setQuestionsAmount] = useState(0);
  const [eventsAmount, setEventsAmount] = useState(0);
  const [videoTime, setVideoTime] = useState(0);

  const [calculatedPlan, setCalculatedPlan] = useState({
    freeVotesCount: 0,
    votePrice: 0,
    chatPrice: 0,
    priceMonthly: 0,
    videoConferencePrice: 0,
    smsPrice: 0,
    generalMeetingPrice: 0,
    reportPrice: 0,
  });
  const [calculation, setCalculation] = useState({
      votesCount: 0,
      paidVotes: 0,
      generalMeetingCount: 0,
      eventsCount: 0,
      reportsCount: 0,
      videoConferenceInMinutes: 0,
      chatMembersCount: 0,
      smsMembersCount: 0,
      extraServices: 0,
      totalPrice: 0
  });


  const usersValidate = usersAmount >= 0 && usersAmount !== null;
  const questionsValidate = questionsAmount >= 0 && questionsAmount !== null;
  const eventsValidate = eventsAmount >= 0 && eventsAmount !== null;

  const isFormValidate = usersValidate && questionsValidate && eventsValidate;
  let resultArray = [];
  let bestPlan;

  const  calculateStripe = (plansToChoose: PlanToChooseResponse[], votersCount: number, eventsCount: number, votesCount: number, generalMeetingCount: number, reportsCount: number, videoConferenceInMinutes: number, chatAllowed: boolean, reportsAllowed: boolean, videoAllowed: boolean, smsEvents: boolean) => {
      return plansToChoose.reduce((prev, planToChoose) => {
        const paidVotes = votesCount - (planToChoose.freeVotesCount * 12) > 0 ? votesCount - (planToChoose.freeVotesCount * 12) : 0;
        const priceForVotes = paidVotes * planToChoose.votePrice;
        const generalMeetingsPrice = generalMeetingCount * planToChoose.generalMeetingPrice;
        const reportsPrice = reportsAllowed ? reportsCount * planToChoose.reportPrice : 0;
        const videoConferencePrice = videoAllowed ? votersCount * videoConferenceInMinutes * planToChoose.videoConferencePrice * generalMeetingCount : 0;
        const chatPrice = chatAllowed ? votersCount * eventsCount * planToChoose.chatPrice : 0;
        const smsPrice = smsEvents ? votersCount * eventsCount * planToChoose.smsPrice : 0;
        const price = planToChoose.priceYearly + priceForVotes + generalMeetingsPrice + reportsPrice + videoConferencePrice + chatPrice + smsPrice;
        resultArray.push({ id: planToChoose.id, price });
        return {
          planId: planToChoose.id,
          price,
        };

      }, {
        planId: '',
        price: Number.MAX_SAFE_INTEGER
      });
  }
  const getMin = () => {
    return resultArray.reduce((min, b) => Math.min(min, b.price), resultArray[0].price);
  }
  const calculate = () => {

    if(!isVideoEnabled) {
      setVideoTime(0);
    }
    const votesCount = usersAmount * questionsAmount * eventsAmount;
    setCalculation((prevState => ({...prevState, votesCount: votesCount})))
    const reportCount = eventsAmount;
    if (isChatEnabled) {
      setCalculation((prevState => ({...prevState,chatMembersCount: usersAmount})));
    }
    if (isSmsEnabled) {
      setCalculation((prevState => ({...prevState,smsMembersCount: usersAmount})));
    }
    if (isPdfEnabled) {
      setCalculation((prevState => ({...prevState, reportsCount: reportCount})));
    }
    let minutesOfConferece = videoTime;

    if (minutesOfConferece) {
      setCalculation((prevState) => ({...prevState, videoConferenceInMinutes: minutesOfConferece * usersAmount}));
    }
    let generalMeeting = 0;
    if (selected === 'walne') {
      generalMeeting = eventsAmount;
    }
    setCalculation((prevState => ({...prevState, generalMeetingCount: generalMeeting})))

    calculateStripe(stripePrices, usersAmount, eventsAmount, votesCount, generalMeeting, reportCount, minutesOfConferece, isChatEnabled, isPdfEnabled, isVideoEnabled, isSmsEnabled);
    const bestplan = getMin();
    const obj = resultArray.findIndex(p => p.price == bestplan);
    bestPlan = resultArray[obj];
    const calculatedPlanIndex = stripePrices.findIndex(p => p.id == bestPlan.id);
    setCalculatedPlan({ ...stripePrices[calculatedPlanIndex] });
    resultArray = [];
    const paidVotes=  votesCount - (stripePrices[calculatedPlanIndex].freeVotesCount * 12) > 0 ? votesCount - (stripePrices[calculatedPlanIndex].freeVotesCount * 12) : 0
    setCalculation((prevState => ({...prevState, paidVotes, eventsCount: eventsAmount})))

    const extraServices =
      (stripePrices[calculatedPlanIndex].votePrice * paidVotes) +
      (selected !== 'default' ? generalMeeting * stripePrices[calculatedPlanIndex].generalMeetingPrice : 0) +
      (isPdfEnabled ? reportCount * stripePrices[calculatedPlanIndex].reportPrice : 0) +
      (minutesOfConferece > 0 ? minutesOfConferece * usersAmount * stripePrices[calculatedPlanIndex].videoConferencePrice * eventsAmount : 0) +
      (isChatEnabled ? usersAmount * stripePrices[calculatedPlanIndex].chatPrice * eventsAmount : 0) +
      (isSmsEnabled ? usersAmount * stripePrices[calculatedPlanIndex].smsPrice * eventsAmount : 0);

    if (isMonthlyPlan) {
      setCalculation((prevState => ({...prevState, totalPrice: (stripePrices[calculatedPlanIndex].priceMonthly * 12) + extraServices})));
    } else {
      setCalculation((prevState => ({...prevState, totalPrice: (stripePrices[calculatedPlanIndex].priceMonthly * 10) + extraServices})));

    }

    if (extraServices > 0) {
      setCalculation((prevState => ({...prevState, extraServices: extraServices})))
    }
    setTimeout(() => {
      scrollTo("#calculator-details")
    }, 80)
  }

  const retryCalculation = () => {
    setTimeout(() => {
      scrollTo("#calculator-top")
    }, 80)

    setSelected('default');
    setVideoTime(0);
  }

  useEffect(()=> {
    calculate();
  },[isMonthlyPlan]);

  return (
    <Layout>
    <CalculatorWrapper>
      <NavWrapper id="calculator-top">
          <Logo
            src={LogoSVG}
          />
          <ClosePageButton
            src={CrossPaginatorBlue}
            onClick={()=> navigate('/')}
          />
      </NavWrapper>
        <CustomHeaderText
          title='Kalkulacja Twoich potrzeb'
          description='Potrzebujemy od Ciebie kilku informacji, żeby dobrać dla Ciebie najlepszy plan subskrybcyjny. Jeżeli szukasz wskazówek możesz kliknąć w podpowiedź przy każdym pytaniu.'
        />
      <TopWrapper>
      <CalculatorInsertDataWrapper>
        <InsertDataBar>
          <InsertDataHeader >Do jakich WYDARZEŃ będziesz używał naszej aplikacji?</InsertDataHeader>
          <RadioButtonsWrapper>
          <CustomRadio
            value="default"
            selected={selected}
            labelText="Zwykłe wydarzenia "
            onChange={setSelected}
          />
            <CustomRadio
              value="walne"
              selected={selected}
              helperLabel=''
              labelText="Wydarzenia z udziałami"
              onChange={setSelected}
            />
          </RadioButtonsWrapper>
        </InsertDataBar>
        <InsertDataBar>
          <InsertDataHeader>Ile średnio osób uczestniczy w jednym WYDARZENIU?</InsertDataHeader>
          <CustomInput
            placeholder="podaj liczbę osób"
            errorMsg="Pole nie może być puste"
            inputValue={usersAmount}
            setValue={(event)=>setUsersAmount(event)}
          />
        </InsertDataBar>
        <InsertDataBar>
          <InsertDataHeader>Ile średnio PYTAŃ będzie zadawanych na jednym WYDARZENIU?</InsertDataHeader>
          <CustomInput
            placeholder="podaj liczbę pytań"
            errorMsg="Pole nie może być puste"
            inputValue={questionsAmount}
            setValue={(event)=>setQuestionsAmount(event)}
          />
        </InsertDataBar>
        <InsertDataBar>
          <InsertDataHeader>Ile średnio WYDARZEŃ będzie odbywało się w ciągu roku?</InsertDataHeader>
          <CustomInput
            placeholder="podaj liczbę wydarzeń"
            errorMsg="Pole nie może być puste"
            inputValue={eventsAmount}
            setValue={(event)=>setEventsAmount(event)}
          />
        </InsertDataBar>
        <InsertDataBar>
          <InsertDataHeader>Wybierz, z ktorych dodatkowych usług chcesz korzystać:</InsertDataHeader>
          <CustomCheckbox
            labelText="Czat"
            setState={() => setChatEnabled(!isChatEnabled)}
            state={isChatEnabled}
          />
          <CustomCheckbox
            labelText="Eksport raportu do formatu PDF"
            setState={() => setPdfEnabled(!isPdfEnabled)}
            state={isPdfEnabled}
          />
          <VideoPopupWrapper>
            <CustomCheckbox
              labelText="Wideokonferencja"
              setState={() => setVideoEnabled(!isVideoEnabled)}
              state={isVideoEnabled}
            />
            {isVideoEnabled &&
            <TimeInputWrapper>
              <span>sredni czas trwania</span>
              <CustomInput
                className='normal'
                placeholder='w minutach'
                inputValue={videoTime}
                setValue={(event)=>setVideoTime(event)}
              />
            </TimeInputWrapper>}
          </VideoPopupWrapper>
          <CustomCheckbox
            labelText="Zaproszenia SMS"
            setState={() => setSmsEnabled(!isSmsEnabled)}
            state={isSmsEnabled}
          />

        </InsertDataBar>
      </CalculatorInsertDataWrapper>
        <TopImage src={CalculatorImage} />
      </TopWrapper>
      <CustomButton
        className={'calculator'}
        onClick={() => isFormValidate ? calculate() : null}
        text="wykonaj kalkulacje"
      />
      <CalculationBottomWrapper id='calculator-details'>
        <CalculatorMainBar
          priceMonthlyProp={calculatedPlan.priceMonthly}
          extraServicesProp={calculation.extraServices}
          totalPriceProp={calculation.totalPrice}
          setYearlyState={() => setMonthlyPlan(false)}
          setMonthlyState={() => setMonthlyPlan(true)}
          isMonthlyPlan={isMonthlyPlan}
        />
        <CalculatorDetails
          isChatEnabled={isChatEnabled}
          isSmsEnabled={isSmsEnabled}
          isPdfEnabled={isPdfEnabled}
          isVideoEnabled={isVideoEnabled}
          calculatedPlan={calculatedPlan}
          calculation={calculation}
          isMonthlyPlan={isMonthlyPlan}
        />
      </CalculationBottomWrapper>

      <CustomButton
        className={'calculator'}
        onClick={() => retryCalculation()}
        text="powtórz kalkulacje"
      />
    </CalculatorWrapper>
    </Layout>
  );
};

export default Calculator;
