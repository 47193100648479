export const stripePrices = [
  {
    "id":"prod_I29JKkeKW9zCOb",
    "active":true,
    "priceMonthly":0,
    "priceYearly":0,
    "isMain":true,
    "freeVotesCount":0,
    "votePrice":50,
    "votePriceId":"price_1IeIdQI9l5Fs2T8ZN7ZsV8hw",
    "generalMeetingPrice":20000,
    "generalMeetingPriceId":"price_1IeIVqI9l5Fs2T8ZJwKW28Qn",
    "reportPrice":3000,
    "reportPriceId":"price_1IeIU6I9l5Fs2T8Ztbw10add",
    "videoConferencePrice":17,
    "videoConferencePriceId":"price_1IeISuI9l5Fs2T8Zlo7a7XOD",
    "chatPrice":100,
    "chatPriceId":"price_1IeISTI9l5Fs2T8ZGSmJLaNI",
    "smsPrice":50,
    "smsPriceId":"price_1IeIRjI9l5Fs2T8Zokf4bdVv",
    "priceMonthlyId":"price_1Iabm3I9l5Fs2T8Zv1sK8q5U",
    "priceYearlyId":"price_1Iabm3I9l5Fs2T8Zv1sK8q5U"
  },
  {
    "id":"prod_I2UmApXX5b5Y3p",
    "active":true,
    "priceMonthly":3000,
    "priceYearly":30000,
    "isMain":true
    ,"freeVotesCount":100,
    "votePrice":30,"votePriceId":"price_1IeIbTI9l5Fs2T8ZEz0E1TzY",
    "generalMeetingPrice":20000,"generalMeetingPriceId":"price_1IeIVqI9l5Fs2T8ZJwKW28Qn",
    "reportPrice":0,
    "reportPriceId":"price_1IeITNI9l5Fs2T8Z4MA1prFx",
    "videoConferencePrice":17,
    "videoConferencePriceId":"price_1IeISuI9l5Fs2T8Zlo7a7XOD",
    "chatPrice":50,
    "chatPriceId":"price_1IeISEI9l5Fs2T8ZLvEyWNzF",
    "smsPrice":30,
    "smsPriceId":"price_1IeIRUI9l5Fs2T8ZYEns5JiK",
    "priceMonthlyId":"price_1IeIkvI9l5Fs2T8ZP1Ctqb3V",
    "priceYearlyId":"price_1IeIkYI9l5Fs2T8ZA9xfGCQ1"
  },
  {
    "id":"prod_IGkboKSair3MLY",
    "active":true,
    "priceMonthly":5000,
    "priceYearly":50000,
    "isMain":true,
    "freeVotesCount":250,
    "votePrice":20,
    "votePriceId":"price_1IeIaYI9l5Fs2T8ZRSm2tSSm",
    "generalMeetingPrice":20000,
    "generalMeetingPriceId":"price_1IeIVqI9l5Fs2T8ZJwKW28Qn",
    "reportPrice":0,
    "reportPriceId":"price_1IeITNI9l5Fs2T8Z4MA1prFx",
    "videoConferencePrice":17,
    "videoConferencePriceId":"price_1IeISuI9l5Fs2T8Zlo7a7XOD",
    "chatPrice":50,
    "chatPriceId":"price_1IeISEI9l5Fs2T8ZLvEyWNzF",
    "smsPrice":30,
    "smsPriceId":"price_1IeIRUI9l5Fs2T8ZYEns5JiK",
    "priceMonthlyId":"price_1IeIjzI9l5Fs2T8ZphQm5dFL",
    "priceYearlyId":"price_1IeIkDI9l5Fs2T8ZD1pSjR0n"
  },
  {
    "id":"prod_IGkcoHglIQi2bN",
    "active":true,
    "priceMonthly":9000,
    "priceYearly":90000,
    "isMain":true,
    "freeVotesCount":500,
    "votePrice":18,
    "votePriceId":"price_1IeIZHI9l5Fs2T8ZZ7TOpyny",
    "generalMeetingPrice":15000,
    "generalMeetingPriceId":"price_1IeIUvI9l5Fs2T8ZDTm9Qfh3",
    "reportPrice":0,
    "reportPriceId":"price_1IeITNI9l5Fs2T8Z4MA1prFx",
    "videoConferencePrice":17,
    "videoConferencePriceId":"price_1IeISuI9l5Fs2T8Zlo7a7XOD",
    "chatPrice":50,
    "chatPriceId":"price_1IeISEI9l5Fs2T8ZLvEyWNzF",
    "smsPrice":30,
    "smsPriceId":"price_1IeIRUI9l5Fs2T8ZYEns5JiK",
    "priceMonthlyId":"price_1IeIjKI9l5Fs2T8ZMAj5PgLs",
    "priceYearlyId":"price_1HgD7WI9l5Fs2T8ZVcJuSsEC"
  },
  {
    "id":"prod_IGkdU0U0065wOp",
    "active":true,
    "priceMonthly":12000,
    "priceYearly":120000,
    "isMain":true,
    "freeVotesCount":500,
    "votePrice":12,
    "votePriceId":"price_1HgDQkI9l5Fs2T8ZHi3LUdr1",
    "generalMeetingPrice":10000,
    "generalMeetingPriceId":"price_1HgDUDI9l5Fs2T8ZZsvHhs1m",
    "reportPrice":0,
    "reportPriceId":"price_1HgDV7I9l5Fs2T8ZJ7cqHEs2",
    "videoConferencePrice":17,
    "videoConferencePriceId":"price_1HgDWcI9l5Fs2T8ZpUMBZMts",
    "chatPrice":50,
    "chatPriceId":"price_1Ib2UUI9l5Fs2T8ZyfJA8WNx",
    "smsPrice":30,
    "smsPriceId":"price_1Ib2fSI9l5Fs2T8Z7LfR8bMy",
    "priceMonthlyId":"price_1HgD8XI9l5Fs2T8ZshXY0Xej",
    "priceYearlyId":"price_1HgD8XI9l5Fs2T8ZhIEP2YRy"
  },
  {
    "id":"prod_IGkeMRkkH7u4Z5",
    "active":true,
    "priceMonthly":25000,
    "priceYearly":250000,
    "isMain":true,
    "freeVotesCount":500,
    "votePrice":5,
    "votePriceId":"price_1HgDQJI9l5Fs2T8ZNiWQe7aB",
    "generalMeetingPrice":10000,
    "generalMeetingPriceId":"price_1HgDUDI9l5Fs2T8ZZsvHhs1m",
    "reportPrice":0,
    "reportPriceId":"price_1HgDV7I9l5Fs2T8ZJ7cqHEs2",
    "videoConferencePrice":17,
    "videoConferencePriceId":"price_1HgDWcI9l5Fs2T8ZpUMBZMts",
    "chatPrice":50,"chatPriceId":"price_1Ib2UUI9l5Fs2T8ZyfJA8WNx",
    "smsPrice":30,
    "smsPriceId":"price_1Ib2fSI9l5Fs2T8Z7LfR8bMy",
    "priceMonthlyId":"price_1HgD9HI9l5Fs2T8ZKW9wgstZ",
    "priceYearlyId":"price_1HgD9HI9l5Fs2T8ZDv8cXhMj"
  }];
