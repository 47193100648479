import styled from "styled-components"
// @ts-ignore
import React, { useState } from "react"
import { parseToMoney } from "../../../utils/parseToMoney"


const TableWrapper = styled.span`
  margin-top: 56.5px;
  padding-top: 47px;
  padding-bottom: 50px;
  width: 1200px;
  border-radius: 30px;
  background-color: #ebf4fd;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  justify-content: center;
  @media(max-width: 1250px) {
    width: 95%;
    padding-left: 0;
  }
`;
const DetailsColumn = styled.span`
  display: flex;
  flex-flow: column nowrap;
  width: 230px;
  align-items: center;
  &.align-right {
    align-items: flex-end;
    margin-right: 120px;
  }
  @media(max-width: 1100px) {
    &.align-right {
      margin-right: 0;
    }
  }
  @media(max-width: 750px) {
    &.align-right {
      align-items: flex-end;
      margin-right: 30px;
    }
    :not(:first-of-type) {
      display: none;
    }
    :nth-of-type(${({ columnIndex }) => columnIndex}) {
      display: flex;
    }
  }
  @media(max-width: 500px) {
    
    &.align-right {
      margin-right: 0;
    }
  }
`;
const OrangeText = styled.span`
  font-size: 14px;
  font-weight: 800;
  color: #fcd5b5;
  &.first {
    padding-top: 41px;
  }
`;
const DescriptionUpper = styled.span`
  font-size: 18px;
  color: #07064e;
  white-space: nowrap;
  font-weight: 600;
  &.total-value {
    font-weight: 800;
    padding-top: 40px;
  }
  &.total-value-text {
    font-weight: 800;
    padding-top: 21px;
  }
  @media(max-width: 400px) {
    font-size: 16px;
  }
`;

const DescriptionLower = styled.span`
  white-space: nowrap;
  font-size: 12px;
  color: #07064e;
  font-weight: 300;
`;
const TextWrapper = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  text-align: right;
  min-height: 44px;
  white-space: normal;
`;
const PriceValue = styled.span`
  font-size: 20px;
  color: #515182;
  padding-top: 40px;
  font-weight: 800;
  &.higher {
    padding-top: 38px;
  }
  &.total-value {
    color: #ffb87e;
    font-size: 26px;
  }
  &.lower {
    padding-top: 13px;
  }
  @media(max-width: 500px) {
    width: 150px;
  }
  @media(max-width: 400px) {
    padding-top: 37px;
    width: 110px;
  }
`;
const MoneySign = styled.span`
  font-size: 14px;
  margin-left: 3px;
  color: #515182;
  font-weight: 600;
`;
const PriceHeader = styled.span`
  text-align: center;
  font: normal normal 800 15px/23px Open Sans;
  letter-spacing: 0;
  color: #07064e;
  padding-bottom: 60px;
  max-width: 150px;
  &.single-line {
    padding-top: 10px;
    padding-bottom: 72px;
  }
  @media(max-width: 500px) {
    width: 150px;
  }
`;
const ChangeSlidesButton = styled.button`
  background-color: #2BF5BA;
  color: #07064E;
  padding: 15px 20px;
  position: absolute;
  left: 5px;
  top: 41%;
  font-weight: 800;
  border-radius: 50%;
  border: none;
  outline: none;
  opacity: 0.5;
  &.right-button {
    right: 5px;
    left: auto;
  }
  :active {
    opacity: 1;
  }
  @media(min-width: 750px) {
    display: none;
  }
`;
const RightArrow = styled.i`
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
`;
const LeftArrow = styled(RightArrow)`
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
`;

const CalculatorDetails = ({ calculation, calculatedPlan, isChatEnabled, isPdfEnabled, isSmsEnabled, isMonthlyPlan }) => {

  const [columnIndex, setColumnIndex] = useState(2);

  const handleIncreaseColumnIndex = () => {
    if (columnIndex < 4) {
      setColumnIndex(columnIndex +1);
    } else {
      setColumnIndex(2);
    }
  }
  const handleDecreaseColumnIndex = () => {
    if (columnIndex !== 2) {
      setColumnIndex(columnIndex -1);
    } else {
      setColumnIndex(4);
    }
  }

  return (
    <TableWrapper>
      <DetailsColumn columnIndex={columnIndex} className='align-right'>
        <TextWrapper>
          <OrangeText className='first'>SUBSKRYPCJA</OrangeText>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>subskrypcja {isMonthlyPlan ? 'miesięczna' : 'roczna'}</DescriptionUpper>
          <DescriptionLower>stała opłata {isMonthlyPlan ? 'miesięczna' : 'roczna'}</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>liczba głosów</DescriptionUpper>
          <DescriptionLower>do wykorzystania w mscu w subskrypcji</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
        <OrangeText>DODATKOWE KOSZTY:</OrangeText>
        </TextWrapper>
          <TextWrapper>
          <DescriptionUpper>koszt dodatkowego głosu</DescriptionUpper>
          <DescriptionLower>opłata za głos poza abonamentem</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>Wydarzenie z udziałami</DescriptionUpper>
          <DescriptionLower>koszt jednego uruchomienia</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>eksport raportu do PDF</DescriptionUpper>
          <DescriptionLower>uruchomienie usług w 1 wydarzeniu</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>wideokonferencja</DescriptionUpper>
          <DescriptionLower>1min video na osobę</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>czat</DescriptionUpper>
          <DescriptionLower>koszt na 1 osobę</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper>zaproszenie sms</DescriptionUpper>
          <DescriptionLower>koszt na 1 osobę</DescriptionLower>
        </TextWrapper>
        <TextWrapper>
          <DescriptionUpper className='total-value-text'>SUMA</DescriptionUpper>
        </TextWrapper>
      </DetailsColumn>
      <DetailsColumn columnIndex={columnIndex}>
        <PriceHeader>SUGEROWANY ABONAMENT</PriceHeader>
        <PriceValue className='lower'>{parseToMoney( isMonthlyPlan ? calculatedPlan.priceMonthly : calculatedPlan.priceMonthly * 10)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{calculatedPlan.freeVotesCount}<MoneySign>głosów</MoneySign></PriceValue>
        <TextWrapper />
        <PriceValue className='higher'>{parseToMoney(calculatedPlan.votePrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculatedPlan.generalMeetingPrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculatedPlan.reportPrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculatedPlan.videoConferencePrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculatedPlan.chatPrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculatedPlan.smsPrice)}<MoneySign>zł</MoneySign></PriceValue>
      </DetailsColumn>

      <DetailsColumn columnIndex={columnIndex}>
        <PriceHeader>TWOJA KALKULACJA</PriceHeader>
        <PriceValue className='lower'>12<MoneySign>mscy</MoneySign></PriceValue>
        <PriceValue>{calculation.votesCount}<MoneySign>głosów</MoneySign></PriceValue>
        <TextWrapper />
        <PriceValue className='higher'>{calculation.paidVotes}<MoneySign>głosów</MoneySign></PriceValue>
        <PriceValue>{calculation.generalMeetingCount}<MoneySign>w roku</MoneySign></PriceValue>
        <PriceValue>{isPdfEnabled ? calculation.reportsCount : 0}<MoneySign>w roku</MoneySign></PriceValue>
        <PriceValue>{calculation.videoConferenceInMinutes}<MoneySign>min</MoneySign></PriceValue>
        <PriceValue>{isChatEnabled ? calculation.chatMembersCount : 0}<MoneySign>osób</MoneySign></PriceValue>
        <PriceValue>{isSmsEnabled ? calculation.smsMembersCount : 0}<MoneySign>osób</MoneySign></PriceValue>
      </DetailsColumn>

      <DetailsColumn columnIndex={columnIndex}>
        <PriceHeader className='single-line'>KOSZTY</PriceHeader>
        <PriceValue className='lower'>{parseToMoney(calculatedPlan.priceMonthly * (isMonthlyPlan ? 12 : 10))}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>0<MoneySign>zł</MoneySign></PriceValue>
        <TextWrapper />
        <PriceValue className='higher'>{parseToMoney(calculation.paidVotes * calculatedPlan.votePrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculation.generalMeetingCount * calculatedPlan.generalMeetingPrice)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{isPdfEnabled ? parseToMoney(calculation.reportsCount * calculatedPlan.reportPrice) : 0}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{parseToMoney(calculation.videoConferenceInMinutes * calculatedPlan.videoConferencePrice * calculation.eventsCount)}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{isChatEnabled ? parseToMoney(calculation.chatMembersCount * calculatedPlan.chatPrice * calculation.eventsCount) : 0}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue>{isSmsEnabled ? parseToMoney(calculation.smsMembersCount * calculatedPlan.smsPrice * calculation.eventsCount) : 0}<MoneySign>zł</MoneySign></PriceValue>
        <PriceValue className='total-value'>{parseToMoney(calculation.totalPrice)}zł</PriceValue>
      </DetailsColumn>
      <ChangeSlidesButton onClick={()=> handleDecreaseColumnIndex()}>
        <LeftArrow />
      </ChangeSlidesButton>
      <ChangeSlidesButton className="right-button" onClick={()=> handleIncreaseColumnIndex()}>
        <RightArrow />
      </ChangeSlidesButton>
    </TableWrapper>
  );
};
export default CalculatorDetails;
