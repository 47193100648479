import { FunctionComponent, useState } from "react"
// @ts-ignore
import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  padding: 12px 20px;
  width: fit-content;
  outline: none !important;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #2AF5BA;
  border-radius: 30px;
  font-weight: 600 !important;
  color: #07064e;
  &.error {
    background-color: #c0c0c0;
  }
  &.calculator {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 25px;
  }
`;

const CustomButton = ({text , onClick, className}) => {

  return (
    <StyledButton className={className} onClick={onClick}>
      {text}
    </StyledButton>
  );
}
export default CustomButton;
