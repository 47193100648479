import { FunctionComponent, useState } from "react"
// @ts-ignore
import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  &.normal {
    margin-bottom: 0;
  }
`;
const StyledInput = styled.input`
  padding: 15px 30px;
  width: fit-content;
  outline: none;
  color: #07064e;
  font-weight: 700;
  appearance: none;
  border-radius: 12px;
  border: 1px solid transparent;
  ::placeholder {
    color: #CBCBCB;
  }
  :focus {
    border: 1px solid #2AF5BA;
  }
  &.error {
    border: 1px solid red;
  }
  @media(max-width: 700px) {
    width: 100%;
  }
`;
const ErrorLabel = styled.p`
    font-size: 13px;
    color: #fd8080;
    font-weight: 800;
`;

const CustomInput = ({ placeholder, errorMsg, inputValue, setValue, errorVisible, className }) => {

  return (
      <InputWrapper className={className}>
        <StyledInput
          placeholder={placeholder}
          value={inputValue === 0 ? '' : inputValue}
          onChange={(event) => setValue(event.target.value)}
          type="text"
        />
        {errorVisible &&  <ErrorLabel>{errorMsg}</ErrorLabel>}
      </InputWrapper>
  );
};
export default CustomInput;
